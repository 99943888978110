<template>
    <div class="container product_application">
        <div class="mb-2">
            <router-link
                class="tab_btn"
                v-for="ind in get_data_list"
                :key="`tab_${ind.id}`"
                :class="nowtab == ind.id ? 'active' : ''"
                :to="`#${ind.id}`"
                >{{ ind.name }}
            </router-link>
        </div>
        <div
            v-for="ind in get_data_list"
            :key="`ind_${ind.id}`"
            v-show="nowtab == ind.id"
        >
            <p class="mb-md-2 mb-0">
                <a
                    class="btn tab_btn"
                    :class="{ active: show_app[ind.id] == '' }"
                    @click="show_app[ind.id] = ''"
                    >{{ $store.state.language.application.all }}
                </a>
                <a
                    class="btn tab_btn"
                    v-for="app in ind.application"
                    :key="app.id"
                    :class="{ active: app.id == show_app[ind.id] }"
                    @click="show_app[ind.id] = app.id"
                    >{{ app.name }}
                </a>
            </p>
            <div class="container px-0">
                <div class="row product_case">
                    <div
                        class="col-md-6 col-lg-4"
                        v-for="p in ind.product"
                        :key="p.id"
                        v-show="
                            show_app[ind.id] == '' ||
                                p.id_industry_application.indexOf(
                                    parseInt(show_app[ind.id])
                                ) > -1
                        "
                    >
                        <productUnit :product="p"></productUnit>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.banner {
    position: relative;
    z-index: 10;

    img {
        width: 100%;
    }
    .title {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        display: inline;
        position: absolute;
        font-size: 4em;
        font-weight: bold;
        line-height: 1em;
        height: 2em;
        left: 0;
        bottom: 5%;
        text-align: justify;
        padding: 0.5em 2em 0;
        box-sizing: border-box;
        min-width: 5em;

        &:after {
            content: ".";
            display: inline-block;
            width: 100%;
            height: 0;
            visibility: hidden;
        }
    }
}
</style>
<script>
import productUnit from "./product_unit.vue";
export default {
    components: { productUnit },
    data() {
        return {
            show_app: {},
            nowtab: "1"
        };
    },
    mounted() {
        this.switch_tab();
    },
    watch: {
        "$route.hash"() {
            this.switch_tab();
        }
    },
    computed: {
        get_data_list() {
            let vue = this,
                output = [];
            if (vue.$store.state.data.industry) {
                vue.$store.state.data.industry.forEach(ind => {
                    if (this.nowtab == "") {
                        this.nowtab = ind.id;
                    }
                    vue.$set(vue.show_app, ind.id, "");
                    let data = Object.assign(
                        {
                            application: vue.$store.state.data.industry_application.filter(
                                app => {
                                    return app.id_industry == ind.id;
                                }
                            ),
                            product: []
                        },
                        ind
                    );
                    // 篩出產業別的機台資料 ps:有點多層
                    data.product = vue.$store.state.data.product.filter(p => {
                        return (
                            p.id_industry_application.findIndex(ip => {
                                return (
                                    data.application.findIndex(ap => {
                                        return ap.id == ip;
                                    }) > -1
                                );
                            }) > -1
                        );
                    });
                    output.push(data);
                });
            }
            return output;
        }
    },
    methods: {
        switch_tab() {
            this.nowtab = !this.$route.hash ? "1" : this.$route.hash.substr(1);
        }
    }
};
</script>
