<template>
    <router-link
        class="product_unit"
        :to="`/product/view/${product.id}#${product.model}`"
    >
        <img class="main" :src="product.main_img" :alt="product.name" />
        <img class="logo" :src="product.logo_img" :alt="product.name" />
        <div class="name mb-3">{{ product.name }}</div>
        <span class="product_more button">
            {{ $store.state.language.product.more }}
            <i class="fa fa-arrow-right"></i>
        </span>
    </router-link>
</template>
<style lang="scss">
.product_unit {
    display: block;
    text-decoration: none;
    transition: all 0.2s;
    box-shadow: none;
    background-color: #fff;
    padding: 1.5em;
    border-radius: 5px;
    margin-left: 5px;
    margin-bottom: 1.5em;
    position: relative;
    .main {
        width: 100%;
        margin: 0 auto;
    }
    .logo {
        height: 70px;
        display: block;
    }
    .name {
        color: #999;
        font-size: 1.3em;
        line-height: 30px;
        height: 60px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    &:hover {
        box-shadow: 3px 3px 5px 3px #ccc;
        top: -1px;
        left: -1px;
        .product_more i {
            position: relative;
            animation-name: more;
            animation-duration: 0.5s;
            animation-iteration-count: infinite;
        }
    }
}
@keyframes more {
    from {
        left: 0;
    }

    to {
        left: 5px;
    }
}
</style>
<script>
export default {
    name: "product_unit",
    props: ["product"]
};
</script>
